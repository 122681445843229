import React, { useEffect, useState } from "react";
import styles from "./smallCards.module.css";
import AnimatedSection from "../../components/animatedSection/AnimatedSection";
import NoData from "../../pages/noData/NoData";
import Popup from "../../components/popup/Popup"; // Import the Popup component
import { Link } from "react-router-dom";

const SmallCards = ({ data }) => {
  const [project, setProject] = useState(null);
  const [link, setLink] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  useEffect(() => {
    if (data) {
      setProject(data);
      setLink("all"); // Setting default link to "all"
    }
  }, [data]);

  if (!project || !project.projects) {
    return <NoData />;
  }

  const handleCardClick = (card) => {
    setSelectedCard(card);
    setShowPopup(true);
  };

  return (
    <div className={styles.single_card_container}>
      <div className={styles.single_card_content_container}>
        <AnimatedSection>
          <div className={styles.single_card_nav_links}>
            <p
              className={styles.single_card_nav_link}
              style={{
                backgroundColor: link === "all" ? "#6a0dad" : "",
                color: link === "all" ? "#ffffff" : "",
              }}
              onClick={() => setLink("all")}
            >
              All
            </p>
            {Object.keys(project.projects).map((e) => (
              <p
                className={styles.single_card_nav_link}
                style={{
                  backgroundColor: link === e ? "#6a0dad" : "",
                  color: link === e ? "#ffffff" : "",
                }}
                key={e}
                onClick={() => setLink(e)}
              >
                {e}
              </p>
            ))}
          </div>
        </AnimatedSection>
        <div className={styles.single_card_content}>
          <AnimatedSection>
            <div className={styles.single_card_cards_container}>
              {link === "all"
                ? Object.keys(project.projects).map((k) =>
                    Object.keys(project.projects[k] || {}).map((e) => (
                      <div
                        key={e}
                        className={styles.single_card_card_container}
                        onClick={() => handleCardClick(project.projects[k][e])}
                      >
                        <img src={project.projects[k][e]?.image} alt={e} />
                      </div>
                    ))
                  )
                : Object.keys(project.projects[link] || {}).map((e) => (
                    <div
                      key={e}
                      className={styles.single_card_card_container}
                      onClick={() => handleCardClick(project.projects[link][e])}
                    >
                      <img src={project.projects[link][e]?.image} alt={e} />
                    </div>
                  ))}
            </div>
          </AnimatedSection>
        </div>
      </div>
      {showPopup && (
        <Popup onClose={() => setShowPopup(false)}>
          {selectedCard && selectedCard.title ? (
            <h2 style={{ cursor: "text" }}>{selectedCard.title}</h2>
          ) : (
            <h2>No title available</h2>
          )}
          {selectedCard && selectedCard.content && (
            <>
              {Object.keys(selectedCard.content).map((e) => {
                const values = selectedCard.content[e];
                return (
                  <div key={e}>
                    <h4
                      style={{
                        textAlign: "left",
                        marginTop: "25px",
                        cursor: "text",
                        width: "fit-content",
                      }}
                    >
                      {e}
                    </h4>
                    {values.map((value, index) => {
                      const isImage =
                        value.includes("Img") ||
                        /\.(jpg|jpeg|png|gif)$/i.test(value);
                      const isLink = /^(http|https):\/\/|^www\./i.test(value);

                      return (
                        <div key={index} style={{ textAlign: "left" }}>
                          {isImage ? (
                            <div style={{ width: "250px", height: "200px" }}>
                              <img
                                src={value}
                                alt={e}
                                style={{
                                  width: "100%", // Adjust as needed
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                              />
                            </div>
                          ) : isLink ? (
                            <Link
                              to={value}
                              style={{
                                color: "blue",
                                textDecoration: "underline",
                              }}
                            >
                              {value}
                            </Link>
                          ) : (
                            <p
                              style={{
                                textAlign: "left",
                                marginTop: "10px",
                                cursor: "text",
                                width: "fit-content",
                              }}
                            >
                              {value}
                            </p>
                          )}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </>
          )}
          {/* Add more popup content as needed */}
        </Popup>
      )}
    </div>
  );
};

export default SmallCards;
