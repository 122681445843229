import React, { useEffect, useState } from "react";
import NavSpacer from "../../components/navSpacer/NavSpacer";
import Heading from "../../components/heading/Heading";
import { HobbiesData } from "../../data/HobbiesData";
import { PortfolioData } from "../../data/PortfolioData";
import AnimatedSection from "../../components/animatedSection/AnimatedSection";
import { useParams } from "react-router-dom";
import SmallCards from "../../sections/smallCards/SmallCards";
import NotFound from "../notFound/NotFound";

const dataMap = {
  portfolio: PortfolioData,
  hobbies: HobbiesData,
};

const validCategories = ["portfolio", "hobbies"];

const Projects = () => {
  const { category, projectId } = useParams(); // Use route parameters
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true); // Track loading state

  useEffect(() => {
    setLoading(true); // Start loading
    const categoryData = dataMap[category]; // Get the category data

    if (validCategories.includes(category)) {
      // Find the correct project based on projectId
      const foundProject = categoryData
        ? Object.values(categoryData).find((proj) => proj.link === projectId)
        : null;

      setProject(foundProject || null); // Set the project or null if not found
    } else {
      setProject(null); // Handle invalid category
    }
    setLoading(false); // End loading
  }, [category, projectId]);

  // Display NotFound if the category is invalid or if the project is not found after loading  (Handling No Data)
  if (!loading && (!validCategories.includes(category) || !project)) {
    return <NotFound />;
  }

  // Loading indicator while loading (Handline Loading)
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <NavSpacer />
      <AnimatedSection>
        <Heading
          title={project?.title || ""}
          description={project?.description || ""}
          hcolor={"#1a1a1a"}
          pcolor={"#56585e"}
        />
      </AnimatedSection>

      {/* {(projectId === "internships" || projectId === "crafting") && (
        <h3>Conditional Rendering Test</h3>
      )} */}

      <SmallCards data={project || {}} />
    </div>
  );
};

export default Projects;
