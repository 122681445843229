import React from "react";
import Header from "../../sections/header/Header";
import ContactUs from "../../sections/contactUs/ContactUs";
import PortfolioHobbies from "../../sections/portfolioHobbies/PortfolioHobbies";

const Home = () => {
  return (
    <div>
      <Header />
      <PortfolioHobbies />
      <ContactUs />
    </div>
  );
};

export default Home;
