import React from "react";
import NavSpacer from "../../components/navSpacer/NavSpacer";

const Journey = () => {
  return (
    <div style={{ backgroundColor: "antiquewhite", padding: "50px" }}>
      <NavSpacer />
      <h3>Work in Progress!</h3>
    </div>
  );
};

export default Journey;
