import React, { useEffect, useState } from "react";
import styles from "./contactUs.module.css";
import AnimatedSection from "../../components/animatedSection/AnimatedSection";
import { GeneralData } from "../../data/GeneralData";

const ContactUs = () => {
  const [contact, setContact] = useState({
    Email: ["contact@mostafaelgamily.com"],
  });

  useEffect(() => {
    setContact(GeneralData.info.contact);
  }, []);
  return (
    <div
      className={styles.contact_container}
      style={{ backgroundImage: `url(${GeneralData.images.contactImg})` }}
    >
      <div className={styles.contact_content_container}>
        <div className={styles.contact_content}>
          <AnimatedSection>
            <div className={styles.contact_heading}>
              <h3>Get in Touch</h3>
              {/* <p>Get in touch</p> */}
            </div>
          </AnimatedSection>
          <AnimatedSection>
            <div className={styles.contact_info}>
              {Object.keys(contact).map((e) => (
                <div key={e}>
                  <h4>{e}</h4>
                  {contact[e].map((i) => (
                    <div key={i}>
                      <p>{i}</p>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </AnimatedSection>
        </div>
        <form className={styles.contact_form}>
          <AnimatedSection>
            <input type="text" placeholder="Enter your Name" required />
            <input type="email" placeholder="Enter your Email" required />
            <input type="text" placeholder="Enter your Message" required />
            <input type="submit" className={styles.contact_submit} />
          </AnimatedSection>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
