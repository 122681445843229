import React from "react";
import styles from "./popup.module.css"; // Import CSS module

const Popup = ({ children, onClose }) => {
  // Function to handle clicks on the overlay
  const handleOverlayClick = (event) => {
    // Trigger the onClose function only if the click is on the overlay (not on the popup content)
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <div className={styles.overlay} onClick={handleOverlayClick}>
      <div className={styles.popup} onClick={(e) => e.stopPropagation()}>
        {children}
        <div className={styles.close_button} onClick={onClose}>
          <div className={styles.close_button_icon_line}></div>
          <div className={styles.close_button_icon_line}></div>
        </div>
        {/* <button className={styles.close_button} onClick={onClose}>
          Close
        </button> */}
      </div>
    </div>
  );
};

export default Popup;
