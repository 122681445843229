// data/portfolioData.js
import internshipImg from "../images/internship.jpeg";
import sky7Img from "../images/sky7Website.png";
import portfolioWeb from "../images/porfolioWeb.png";
import webImg from "../images/webdev.png";
import frostmourneImg from "../images/frostmourne.png";
import frostmourneInspo from "../images/frostmourneInspo.jpg";

export const PortfolioData = {
  "Internship Details": {
    category: "portfolio",
    title: "Internship Details",
    description: "Experience gained during internship programs",
    link: `internships`,
    image: internshipImg,
    projects: {
      "Studiyo Lab": {
        "Frostmourne Sword Replica": {
          title: "Frostmourne Sword Replica",
          image: frostmourneImg,
          content: {
            Inspiration: [frostmourneInspo],
          },
        },
      },
    },
  },
  "Web Development": {
    category: "portfolio",
    title: "Web Development",
    description: "Full stack projects showcasing innovative tech solutions.",
    link: `webdevelopment`,
    image: webImg,
    projects: {
      Websites: {
        "Portfolio Website": {
          title: "Portfolio Website",
          image: portfolioWeb,
          content: {
            "Link: ": ["https://www.mostafaelgamily.com"],
          },
        },
        "Sky7 Egypt Website": {
          title: "Sky7 Egypt Website",
          image: sky7Img,
          content: {
            "Link: ": ["https://sky7.mostafaelgamily.com"],
          },
        },
      },
    },
  },
};
