import React, { useEffect, useState } from "react";
import styles from "./header.module.css";
import AnimatedSection from "../../components/animatedSection/AnimatedSection";
import Button from "../../components/button/Button";
import { GeneralData } from "../../data/GeneralData";

const Header = () => {
  const [hero, setHero] = useState("");
  useEffect(() => {
    setHero(GeneralData.images.hero);
  }, []);
  return (
    <div
      className={styles.header_container}
      style={{ backgroundImage: `url(${hero})` }}
    >
      <div className={styles.header_content_container}>
        <AnimatedSection>
          <h2>Welcome</h2>
        </AnimatedSection>

        <AnimatedSection>
          <p>Discover my Journey and Creative Adventures here</p>
        </AnimatedSection>

        <AnimatedSection>
          <Button
            title={"Portfolio"}
            bgcolor={"#6a0dad"}
            hoverbgcolor={"transparent"}
            fontcolor={"#ffffff"}
            hoverfontcolor={"#ffffff"}
            link={"/portfolio"}
          />
        </AnimatedSection>
      </div>
    </div>
  );
};

export default Header;
