import React, { useEffect, useState } from "react";
import Heading from "../../components/heading/Heading";
import styles from "./portfolioHobbies.module.css";
import { MdOutlineArrowRightAlt } from "react-icons/md";
import AnimatedSection from "../../components/animatedSection/AnimatedSection";
import { Link } from "react-router-dom";
import { GeneralData } from "../../data/GeneralData";

const PortfolioHobbies = () => {
  const [portfolioImg, setPortfolioImg] = useState("");
  const [hobbiesImg, setHobbiesImg] = useState("");

  useEffect(() => {
    setPortfolioImg(GeneralData.images.portfolio);
    setHobbiesImg(GeneralData.images.hobbies);
  }, []);

  return (
    <div className={styles.ph_container}>
      <Heading title={"Discover"} hcolor={"#1a1a1a"} pcolor={"#56585e"} />
      <div className={styles.ph_content_container}>
        <Link to={`/portfolio`}>
          <AnimatedSection>
            <div className={styles.project}>
              <div className={styles.project_img}>
                <img src={portfolioImg} alt="project" />
              </div>
              <div className={styles.project_info}>
                <div className={styles.project_title}>
                  <h3>Portfolio</h3>
                  <h3>
                    <MdOutlineArrowRightAlt />
                  </h3>
                </div>
                <p>
                  The Goal is to gain as much experience as possible, Explore my
                  Portfolio
                </p>
              </div>
            </div>
          </AnimatedSection>
        </Link>

        <Link to={`/hobbies`}>
          <AnimatedSection>
            <div className={styles.project}>
              <div className={styles.project_img}>
                <img src={hobbiesImg} alt="project" />
              </div>
              <div className={styles.project_info}>
                <div className={styles.project_title}>
                  <h3>Hobbies</h3>
                  <h3>
                    <MdOutlineArrowRightAlt />
                  </h3>
                </div>
                <p>
                  Life is too short to only have one hobby, Explore my Hobbies
                </p>
              </div>
            </div>
          </AnimatedSection>
        </Link>
      </div>
    </div>
  );
};

export default PortfolioHobbies;
