import React, { useState } from "react";
import styles from "./nav.module.css";
import logoBlack from "../../images/logoblack.png";
import logoWhite from "../../images/logowhite.png";
import { Link, NavLink } from "react-router-dom";
import ToggleButton from "../../components/toggleButton/ToggleButton";

const Nav = () => {
  const [navToggle, setNavToggle] = useState(false);

  return (
    <div className={styles.nav_container}>
      <div className={styles.nav_content_container}>
        <Link to={"/"}>
          <img src={logoBlack} alt="Logo" />
        </Link>

        <div className={navToggle && styles.nav_open}>
          <ToggleButton navToggle={navToggle} setNavToggle={setNavToggle} />
        </div>

        <div className={styles.nav_links_container}>
          <NavLink to={"/"} className={styles.nav_link}>
            Home
          </NavLink>
          <NavLink to={"journey"} className={styles.nav_link}>
            Journey
          </NavLink>
          <NavLink to={"/portfolio"} className={styles.nav_link}>
            Portfolio
          </NavLink>
          <NavLink to={"/hobbies"} className={styles.nav_link}>
            Hobbies
          </NavLink>
          <NavLink to={"/blog"} className={styles.nav_link}>
            Blog
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Nav;
