import React from "react";
import styles from "./cards.module.css";
import { Link } from "react-router-dom";
import AnimatedSection from "../../components/animatedSection/AnimatedSection";
import { MdOutlineArrowRightAlt } from "react-icons/md";

const Cards = ({ data, baseRoute }) => {
  // Check if data exists and is an object
  const isDataAvailable =
    data && typeof data === "object" && Object.keys(data).length > 0;

  if (!isDataAvailable) {
    return (
      <div className={styles.projects_container}>
        <div className={styles.projects_content_container}>
          <p>No projects available</p>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.projects_container}>
      <div className={styles.projects_content_container}>
        {Object.keys(data).map((key) => {
          const project = data[key];
          return (
            <Link
              to={`/${baseRoute}/${project.link}`}
              key={key}
              state={{ project }}
            >
              <AnimatedSection>
                <div className={styles.project}>
                  <div className={styles.project_img}>
                    <img src={project.image} alt="project" />
                  </div>
                  <div className={styles.project_info}>
                    <div className={styles.project_title}>
                      <h3>{key}</h3>
                      <h3>
                        <MdOutlineArrowRightAlt />
                      </h3>
                    </div>
                    <p>{project.description}</p>
                  </div>
                </div>
              </AnimatedSection>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Cards;

// import React from "react";
// import styles from "./cards.module.css";
// import { Link } from "react-router-dom";
// import AnimatedSection from "../../components/animatedSection/AnimatedSection";
// import { MdOutlineArrowRightAlt } from "react-icons/md";

// const Cards = ({ data, baseRoute }) => {
//   return (
//     <div className={styles.projects_container}>
//       <div className={styles.projects_content_container}>
//         {Object.keys(data).map((key) => {
//           const project = data[key];
//           return (
//             <Link
//               to={`/${baseRoute}/${project.link}`}
//               key={key}
//               state={{ project }}
//             >
//               <AnimatedSection>
//                 <div className={styles.project}>
//                   <div className={styles.project_img}>
//                     <img src={project.image} alt="project" />
//                   </div>
//                   <div className={styles.project_info}>
//                     <div className={styles.project_title}>
//                       <h3>{key}</h3>
//                       <h3>
//                         <MdOutlineArrowRightAlt />
//                       </h3>
//                     </div>
//                     <p>{project.description}</p>
//                   </div>
//                 </div>
//               </AnimatedSection>
//             </Link>
//           );
//         })}
//       </div>
//     </div>
//   );
// };

// export default Cards;
