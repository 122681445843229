// data/hobbiesData.js
import adrenalineImg from "../images//adrenaline.webp";
import parachutingImg from "../images/parachuting.jpg";
import horseridingImg from "../images/horseriding.jpg";
import craftingImg from "../images/crafting.webp";
import woodworkingImg from "../images/woodworking.jpeg";
import woodworkingImg2 from "../images/woodworking2.jpg";
import printingImg from "../images/3dprinting.webp";
import leatherImg from "../images/leather.jpg";

export const HobbiesData = {
  "Adrenaline Experiences": {
    category: "hobbies",
    title: "Adrenaline Experiences",
    description:
      "Documenting thrilling adventures like parachuting and horseback riding.",
    link: `adrenaline`,
    image: adrenalineImg,
    projects: {
      Skydiving: {
        "Parachuting First Jump": {
          title: "Parachuting First Jump",
          image: parachutingImg,
          content: {
            // key: []
          },
        },
      },
      Horseriding: {
        "Free Ride": {
          title: "Free Ride - Egypt",
          image: horseridingImg,
          content: {
            // key: []
          },
        },
      },
    },
  },
  "Crafting Projects": {
    category: "hobbies",
    title: "Crafting Projects",
    description: "Creative projects in woodworking and 3D printing.",
    link: `crafting`,
    image: craftingImg,
    projects: {
      woodworking: {
        a: {
          title: "Wood",
          image: woodworkingImg,
          content: {
            // key: []
          },
        },
      },
      "3D Printing": {
        a: {
          title: "3D",
          image: printingImg,
          content: {
            // key: []
          },
        },
      },
      "Leather Crafting": {
        a: {
          title: "Leather",
          image: leatherImg,
          content: {
            // key: []
          },
        },
      },
    },
  },
};
