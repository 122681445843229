import portfolioImg from "../images/portfolio.webp";
import hobbiesImg from "../images/hobbies.jpg";
import heroImg from "../images/hero.jpg";
import horseridingImg from "../images/horseriding.jpg";

export const GeneralData = {
  info: {
    contact: {
      Email: ["contact@mostafaelgamily.com"],
    },
  },
  images: {
    hero: heroImg,
    contactImg: heroImg,
    portfolio: portfolioImg,
    hobbies: hobbiesImg,
  },
};
