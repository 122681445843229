import React, { useEffect, useState } from "react";
import styles from "./footer.module.css";
import AnimatedSection from "../../components/animatedSection/AnimatedSection";
import { GeneralData } from "../../data/GeneralData";

const Footer = () => {
  const [email, setEmail] = useState([]);

  useEffect(() => {
    setEmail(GeneralData.info.contact.Email);
  }, []);

  return (
    <div className={styles.footer_container}>
      <div className={styles.footer_content_container}>
        <AnimatedSection>
          <div className={styles.footer_a}>
            <h3>Mostafa ElGamily</h3>
            <p className={styles.footer_description}>
              Discover my Journey and Creative Adventures here
            </p>
          </div>
        </AnimatedSection>

        <AnimatedSection>
          <div className={styles.footer_b}>
            <h4>Contact</h4>
            <p>{email && email[0]}</p>
          </div>
        </AnimatedSection>

        <AnimatedSection>
          <div className={styles.footer_c}>
            <h4>Join my Newsletter</h4>
            <form className={styles.footer_form}>
              <input type="email" placeholder="Enter Your Email" required />
              <input type="submit" className={styles.footer_submit} />
            </form>
          </div>
        </AnimatedSection>
      </div>

      <AnimatedSection>
        <p className={styles.footer_copy}>© 2024. All rights reserved.</p>
      </AnimatedSection>
    </div>
  );
};

export default Footer;
