import React, { useEffect, useState } from "react";
import { HobbiesData } from "../../data/HobbiesData";
import { PortfolioData } from "../../data/PortfolioData";
import NavSpacer from "../../components/navSpacer/NavSpacer";
import Cards from "../../sections/cards/Cards";
import Heading from "../../components/heading/Heading";
import AnimatedSection from "../../components/animatedSection/AnimatedSection";
import { useParams } from "react-router-dom";
import NotFound from "../notFound/NotFound";

const dataMap = {
  portfolio: PortfolioData,
  hobbies: HobbiesData,
};

const validCategories = ["portfolio", "hobbies"];

const Project = () => {
  const { category } = useParams();
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true); // Track loading state

  useEffect(() => {
    setLoading(true); // Start loading
    if (validCategories.includes(category)) {
      const categoryData = dataMap[category];
      setProject(categoryData);
    } else {
      setProject(null); // Optionally handle this case
    }
    setLoading(false); // End loading
  }, [category]);

  // Redirect to /notfound if the category is invalid (Handling No Data)
  if (!validCategories.includes(category)) {
    return <NotFound />;
  }

  // Loading indicator while loading (Handline Loading)
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <NavSpacer />
      <AnimatedSection>
        <Heading
          title={
            category === "portfolio"
              ? "Portfolio"
              : category === "hobbies"
              ? "Hobbies"
              : ""
          }
          description={
            category === "portfolio"
              ? "Explore experiences I gained along with a collection of innovative and creative projects showcased here."
              : category === "hobbies"
              ? "Explore a collection of innovative and creative projects showcased here."
              : ""
          }
          hcolor={"#1a1a1a"}
          pcolor={"#56585e"}
        />
      </AnimatedSection>

      {/* Conditionally render content based on category */}
      {/* {(category === "hobbies") && (
              <h3>Conditional Rendering Test</h3>
            )} */}

      <Cards data={project || {}} baseRoute={category} />
    </div>
  );
};

export default Project;
