import React from "react";
import styles from "./NotFound.module.css";
import NavSpacer from "../../components/navSpacer/NavSpacer";

const NotFound = () => {
  return (
    <div>
      <div className={styles.not_container}>
        <NavSpacer />
        NotFound
      </div>
    </div>
  );
};

export default NotFound;
