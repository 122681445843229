import { Route, Routes } from "react-router-dom";
import "./App.css";
import Nav from "./sections/nav/Nav";
import Footer from "./sections/footer/Footer";
import Home from "./pages/home/Home";
import Project from "./pages/project/Project";
import Journey from "./pages/journey/Journey";
import NotFound from "./pages/notFound/NotFound";
import ScrollToTop from "./components/scrollToTop/ScrollToTop";
import Projects from "./pages/projects/Projects";

function App() {
  return (
    <div className="App">
      <Nav />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/journey" element={<Journey />} />
        <Route path="/blog" element={<Journey />} />
        <Route path="/:category" element={<Project />} />
        <Route path="/:category/:projectId" element={<Projects />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
